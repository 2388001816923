<template>
  <div style="position: relative">
    <q-linear-progress
      v-if="loadingEmails || emailsAll.length === 0"
      indeterminate
      size="xs"
      color="black"
    />

    <div class="text-center">{{ lastUpdate }}</div>

    <!-- Larger screens -->
    <div
      v-if="$q.screen.gt.md"
      class="q-pb-lg"
      style="overflow-y: auto; position: relative"
    >
      <div
        v-for="email in emailsAll"
        :key="email.email_id"
        :id="email.emailKeyUnique"
        :class="scrollToId === email.emailKeyUnique && 'bg-grey-4'"
      >
        <!-- Divider -->
        <div class="row justify-center" style="position: relative">
          <div
            class="q-px-md cursor-pointer bg-grey-4"
            style="position: absolute; top: -2px"
            :style="email.divider_email === 1 ? 'height: 0px;' : 'height: 5px;'"
            @click="setDivider(email)"
          ></div>
        </div>
        <q-separator
          :size="email.divider_email === 1 ? '4px' : '1px'"
          :color="email.divider_email === 1 ? 'black' : 'grey-4'"
        />

        <div class="row items-center q-py-sm q-px-md" style="min-height: 46px">
          <div class="row items-center full-width">
            <div class="col-2 row items-start">
              <div class="row items-center">
                <div
                  class="col q-pl-xs cursor-pointer"
                  @click="goToFarm(email, 'emails')"
                >
                  <span
                    :style="
                      'border-bottom: solid 1px ' +
                      ($q.dark.isActive ? 'white' : 'black')
                    "
                  >
                    {{ farmName(email.farm_id) }}
                  </span>
                </div>
              </div>
            </div>

            <span v-if="email.locationCount > 1" class="q-pl-sm text-blue-14">
              ( {{ email.webfolder }} )
            </span>

            <div class="col items-center q-pl-sm">
              <div
                v-for="(alert, i) in email.emailBody"
                :key="alert.msg + email.email_id + i"
              >
                {{ alert.pond }} -
                {{ alert.msg }}
              </div>
            </div>

            <div>
              <div class="col-auto row justify-end items-center q-pl-md">
                <div class="q-pr-sm">
                  <q-btn
                    v-if="email.numTwoDayDuplicates > 1"
                    unelevated
                    :label="email.numTwoDayDuplicates"
                    color="pink-5"
                    text-color="black"
                    padding="0px sm"
                    style="width: 32px"
                    size="md"
                    class="q-mx-xs"
                    @click="showDuplicates(email)"
                  />
                </div>

                <div
                  class="col-auto text-caption text-cyan-6"
                  style="min-width: 164px"
                >
                  {{ email.formattedDate }}
                </div>

                <div class="col-auto" style="width: 48px">
                  <q-btn
                    v-if="email.multiDays"
                    unelevated
                    :label="email.multiDaysEmails.length"
                    color="amber-14"
                    text-color="black"
                    padding="1px 6px"
                    style="width: 32px"
                    size="md"
                    class="q-mx-sm"
                    @click="showMultiDays(email)"
                  />
                </div>

                <div class="col-auto">
                  <q-btn
                    v-if="email.hasWorkLog"
                    unelevated
                    label="Work Log"
                    icon="assignment"
                    color="cyan-6"
                    text-color="black"
                    padding="xs sm"
                    size="sm"
                    class="q-mr-sm"
                  >
                    <q-badge
                      v-if="email.numWorkLogs > 1"
                      rounded
                      color="pink-6"
                      floating
                      >{{ email.numWorkLogs }}</q-badge
                    >
                    <q-menu>
                      <q-list style="min-width: 100px">
                        <q-item clickable v-close-popup @click="addLog(email)">
                          <div class="row items-center">
                            <div class="q-pr-sm">
                              <q-icon name="add" color="black" size="18px" />
                            </div>
                            <div>Add Worklog</div>
                          </div>
                        </q-item>
                        <q-separator />
                        <q-item
                          clickable
                          v-close-popup
                          @click="workLogAction(email, 'screenshot')"
                        >
                          <div class="row items-center">
                            <div class="q-pr-sm">
                              <q-icon
                                name="add_a_photo"
                                color="black"
                                size="18px"
                              />
                            </div>
                            <div>Add Screenshot</div>
                          </div>
                        </q-item>
                        <q-separator />
                        <q-item
                          clickable
                          v-close-popup
                          @click="workLogAction(email, 'view')"
                        >
                          <div class="row items-center">
                            <div class="q-pr-sm">
                              <q-icon
                                name="visibility"
                                color="black"
                                size="18px"
                              />
                            </div>
                            <div>View Worklog</div>
                          </div>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                  <q-btn
                    v-if="!email.hasWorkLog"
                    outline
                    label="Work Log"
                    icon="content_paste"
                    color="cyan-6"
                    text-color="cyan-6"
                    padding="xs sm"
                    size="sm"
                    class="q-mr-sm"
                    @click="addLog(email)"
                  />
                  <q-btn
                    outline
                    label="Home"
                    color="purple"
                    padding="xs sm"
                    size="sm"
                    class="q-mr-sm"
                    @click="openInNewTab(email.homeUrl)"
                  />
                  <q-btn
                    outline
                    label="Buoy"
                    color="purple"
                    padding="xs sm"
                    size="sm"
                    class="q-mr-sm"
                    @click="openInNewTab(email.buoyUrl)"
                  />
                  <q-btn
                    outline
                    label="Config"
                    color="purple"
                    padding="xs sm"
                    size="sm"
                    class="q-mr-sm"
                    @click="openInNewTab(email.configUrl)"
                  />
                  <q-btn
                    outline
                    icon="archive"
                    color="purple"
                    padding="xs sm"
                    size="sm"
                    @click="(evt) => markArchived(email, evt)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <q-separator />

      <div
        v-if="
          this.emailsAll.length > 0 &&
          (maxEmailsLen > displayNumEmails || loadingEmails)
        "
        class="text-center q-pt-xl q-pb-lg"
      >
        <q-btn
          rounded
          outline
          :loading="loadingEmails"
          label="more"
          icon="expand_more"
          color="cyan-6"
          padding="xs"
          style="width: 200px"
          @click="displayMore"
        />
      </div>
    </div>

    <!-- Smaller screens -->
    <div
      v-if="$q.screen.lt.lg"
      class="q-pb-lg"
      style="overflow-y: auto; position: relative"
    >
      <div
        v-for="email in emailsAll"
        :key="email.email_id"
        :id="email.emailKeyUnique"
        :class="scrollToId === email.emailKeyUnique && 'bg-grey-4'"
      >
        <!-- Divider -->
        <div class="row justify-center" style="position: relative">
          <div
            class="q-px-md cursor-pointer bg-grey-4"
            style="position: absolute; top: -2px"
            :style="email.divider_email === 1 ? 'height: 0px;' : 'height: 5px;'"
            @click="setDivider(email)"
          ></div>
        </div>
        <q-separator
          :size="email.divider_email === 1 ? '4px' : '1px'"
          :color="email.divider_email === 1 ? 'black' : 'grey-4'"
        />

        <div class="row full-width q-pt-sm q-px-md">
          <div class="col-12 row">
            <div class="col row items-center">
              <div
                class="col cursor-pointer"
                @click="goToFarm(email, 'emails')"
              >
                <span
                  :style="
                    'border-bottom: solid 1px ' +
                    ($q.dark.isActive ? 'white' : 'black')
                  "
                >
                  {{ farmName(email.farm_id) }}
                </span>
              </div>
            </div>

            <div class="col-auto row justify-end q-pl-xs">
              <div>
                <span v-if="email.hasWorkLog"> </span>

                <q-btn
                  v-if="email.numTwoDayDuplicates > 1"
                  unelevated
                  :label="email.numTwoDayDuplicates"
                  color="pink-5"
                  text-color="black"
                  padding="0px sm"
                  style="width: 26px"
                  size="md"
                  class="q-mx-xs"
                  @click="showDuplicates(email)"
                />
                <q-btn
                  v-if="email.multiDays"
                  unelevated
                  :label="email.multiDaysEmails.length"
                  color="amber-14"
                  text-color="black"
                  padding="0px sm"
                  style="width: 26px"
                  size="md"
                  class="q-mx-xs"
                  @click="showMultiDays(email)"
                />

                <q-btn
                  v-if="email.hasWorkLog"
                  unelevated
                  icon="assignment"
                  color="cyan-6"
                  text-color="black"
                  padding="4px 5px"
                  style="width: 26px"
                  size="sm"
                  class="q-mx-xs"
                >
                  <q-badge
                    v-if="email.numWorkLogs > 1"
                    rounded
                    color="pink-6"
                    floating
                    >{{ email.numWorkLogs }}</q-badge
                  >
                  <q-menu>
                    <q-list style="min-width: 100px">
                      <q-item clickable v-close-popup @click="addLog(email)">
                        <div class="row items-center">
                          <div class="q-pr-sm">
                            <q-icon name="add" color="black" size="18px" />
                          </div>
                          <div>Add Worklog</div>
                        </div>
                      </q-item>
                      <q-separator />
                      <q-item
                        clickable
                        v-close-popup
                        @click="workLogAction(email, 'screenshot')"
                      >
                        <div class="row items-center">
                          <div class="q-pr-sm">
                            <q-icon
                              name="add_a_photo"
                              color="black"
                              size="18px"
                            />
                          </div>
                          <div>Add Screenshot</div>
                        </div>
                      </q-item>
                      <q-separator />
                      <q-item
                        clickable
                        v-close-popup
                        @click="workLogAction(email, 'view')"
                      >
                        <div class="row items-center">
                          <div class="q-pr-sm">
                            <q-icon
                              name="visibility"
                              color="black"
                              size="18px"
                            />
                          </div>
                          <div>View Worklog</div>
                        </div>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
                <q-btn
                  v-if="!email.hasWorkLog"
                  outline
                  icon="content_paste"
                  color="cyan-6"
                  text-color="cyan-6"
                  padding="3px 5px"
                  style="width: 26px"
                  size="sm"
                  class="q-mx-xs"
                  @click="addLog(email)"
                >
                  <div class="full-width" style="padding-top: 1px"></div>
                </q-btn>
                <q-btn
                  outline
                  icon="archive"
                  color="purple"
                  padding="3px 5px"
                  size="sm"
                  class="q-mx-xs"
                  @click="(evt) => markArchived(email, evt)"
                >
                  <div class="full-width" style="padding-top: 1px"></div>
                </q-btn>
              </div>
            </div>
          </div>

          <div
            v-if="email.locationCount > 1"
            class="col-12 text-blue-14 q-pt-xs"
          >
            ( {{ email.webfolder }} )
          </div>

          <div class="col-12 q-pt-sm" :class="$q.screen.lt.lg ? 'q-pb-sm' : ''">
            <div
              v-for="(alert, i) in email.emailBody"
              :key="alert.msg + email.email_id + i"
            >
              {{ alert.pond }} -
              {{ alert.msg }}
            </div>
          </div>

          <div class="col-12 row items-end text-caption text-cyan-6">
            <div class="q-pb-sm">
              <q-btn
                outline
                label="Home"
                color="purple"
                size="sm"
                padding="3px 5px"
                class="q-mr-sm"
                @click="openInNewTab(email.homeUrl)"
              />
              <q-btn
                outline
                label="Buoy"
                color="purple"
                padding="3px 5px"
                size="sm"
                class="q-mr-sm"
                @click="openInNewTab(email.buoyUrl)"
              />
              <q-btn
                outline
                label="Config"
                color="purple"
                padding="3px 5px"
                size="sm"
                @click="openInNewTab(email.configUrl)"
              />
            </div>

            <q-space />
            <div style="padding-bottom: 3px">
              {{ email.formattedDate }}
            </div>
          </div>
        </div>
      </div>

      <q-separator color="grey-5" />
      <div
        v-if="
          this.emailsAll.length > 0 &&
          ($store.state.emails.length > displayNumEmails || loadingEmails)
        "
        class="text-center q-py-xl q-pb-md"
      >
        <q-btn
          rounded
          outline
          label="more"
          icon="expand_more"
          :loading="loadingEmails"
          color="cyan-6"
          padding="xs"
          style="width: 200px"
          @click="displayMore"
        />
      </div>
    </div>

    <!-- Display Duplicates Dialog -->
    <q-dialog position="top" v-model="duplicatesDialog">
      <EmailDuplicates
        :duplicateEmails="duplicateEmails"
        :twoDayDuplicates="twoDayDuplicates"
        :duplicateEmailsType="duplicateEmailsType"
      />
    </q-dialog>

    <!-- Edit Worklog -->
    <q-dialog position="top" v-model="dialogEditWorklog" persistent>
      <WorkLogEdit
        :farmId="selectedWorkLog.farm_id"
        :workLog="selectedWorkLog"
        @cancel="dialogEditWorklog = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import EmailDuplicates from '@/components/emails/EmailDuplicates.vue';
import WorkLogEdit from '@/components/workLogs/WorkLogEdit';

import store from '@/store';
import { parseTimeStamp } from '@/lib/date-utils';
import { colors, date } from 'quasar';
import { cloneObj, decoder } from '@/lib/helpers.js';
import {
  emailMsgExtractor,
  emailKeyBuilder,
  emailKeyBuilderUnique,
  splitIntoDays,
  getTwoDayDuplicates,
  getTimeDiff,
  checkForWorkLog
} from '@/lib/emailHelpers.js';
import { todoOptions } from '@/lib/static-data';

export default {
  name: 'EmailPage',
  components: {
    EmailDuplicates,
    WorkLogEdit
  },
  data() {
    return {
      assignedTo: null,
      dialogEditWorklog: false,
      dispatchingUpdate: false,
      displayNumEmails: 300,
      displayUpdateNotification: false,
      optimisticUpdates: {
        dividerChanged: false,
        dividerId: null,
        archivedList: []
      },
      duplicatesDialog: false,
      duplicateEmails: [],
      duplicateEmailsType: 'all',
      emailMsg: '',
      emailsAll: [],
      emailsAllComplete: [],
      emailsShowAmount: 100,
      emailsInbox: [],
      emailsInboxComplete: [],
      lastUpdate: null,
      loadingEmails: true,
      maxEmailsLen: 0,
      notifyStr: '',
      officeNotes: '',
      officeNotesComplete: '',
      parseTimeStamp,
      selectedEmail: null,
      selectedWorkLog: {},
      twoDayDuplicates: [],
      scrollToId: '',
      scrollToIndex: 0
    };
  },
  mounted() {
    this.setEmails();
    this.scrollTo();
  },
  methods: {
    addLog(email) {
      const emailMsg =
        email.emailBody.map((x) => `${x.pond} - ${x.msg}`).join(' --- ') +
        ` (${email.formattedDate})`;
      const officeNotes = `[ Email: ${emailMsg} ]`;

      this.selectedWorkLog = {
        id: 'new',
        todo_flag: 8,
        todo_complete: null,
        todo_complete_by: null,
        attachments: [],
        comments: [],
        office_comments: '',
        chats: [],
        last_chat_date: null,
        reminders: [],
        farm_id: email.farm_id,
        location_id: email.location_id,
        work_item: null,
        work_cat: 3,
        pond_id: null,
        computer_id: null,
        notes: '',
        office_notes: officeNotes,
        time: 0,
        assigned_to: null,
        date: date.formatDate(date.startOfDate(new Date(), 'day'), 'X'),
        field_values: {},
        billed: 0,
        billable: false,
        billed_changed_by: null,
        billed_changed_on: null
      };

      this.dialogEditWorklog = true;
    },
    displayMore() {
      this.displayNumEmails += 100;
      this.setEmails();
    },
    farmName(farmId) {
      if (farmId === 0) {
        return 'SPAM EMAIL !!!';
      }
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      return farm ? decoder(farm.farm_name) : '';
    },
    goToFarm(email, tab) {
      const scrollObj = {
        emailKeyUnique: email.emailKeyUnique,
        goStatus: 'active'
      };

      this.$store.dispatch('setEmailScrollTo', scrollObj);
      this.$store.dispatch('setWorkLogScrollTo', email.workLogId);
      this.$router.push(`/farm/${email.farm_id}/${tab}/`);
    },
    markArchived(email, evt) {
      if (evt.altKey) {
        this.markArchivedConfirmed(email);
        return;
      }

      const clonedEmail = cloneObj(email);

      let message = '';
      for (const msg of clonedEmail.msg) {
        message += '<br>' + msg;
      }

      message +=
        '<br><br><b>Are you sure you want to mark this email as archived?</b>';

      if (this.$q.screen.gt.sm) {
        message +=
          '<br><br><span class="text-caption">Alt + Click to skip this dialog</span>';
      }

      this.$q
        .dialog({
          title: this.farmName(email.farm_id),
          message,
          html: true,
          ok: {
            label: 'Archive',
            color: 'purple'
          },
          cancel: {
            label: 'Cancel',
            flat: true,
            color: 'purple'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.markArchivedConfirmed(email);
        });
    },
    async markArchivedConfirmed(email) {
      email.multiDaysEmails = [];
      email.duplicatesArr = [];
      email.twoDayDuplicates = [];
      email.archived = 1;
      this.optimisticUpdates.archivedList.push(email.id);

      const emailIndex = this.emailsAll.findIndex((x) => x.id === email.id);
      if (email.divider_email === 1 && emailIndex > -1) {
        if (this.emailsAll[emailIndex + 1]) {
          this.setDivider(this.emailsAll[emailIndex + 1]);
        } else if (this.emailsAll[emailIndex - 1]) {
          this.setDivider(this.emailsAll[emailIndex - 1]);
        }
      }

      if (emailIndex > -1) {
        this.emailsAll.splice(emailIndex, 1);
      }

      store.dispatch('updateEmails', { email }).then((results) => {
        // following line necessary to disable green checkbox
        results.disableCheck = true;

        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response !== 'success') {
            this.$q.notify({
              message: this.farmName(email.farm_id) + ' archive failed',
              color: 'red-14',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
          }
        });
      });

      this.$q.notify({
        message: this.farmName(email.farm_id) + ' archived',
        position: 'bottom',
        color: 'purple',
        textColor: this.$q.dark.isActive ? 'black' : 'white'
      });
    },
    openInNewTab(url) {
      window.open(url);
    },
    scrollTo() {
      const scrollObj = this.$store.state.emailScrollTo;
      if (scrollObj.goStatus === 'go') {
        this.scrollToId = scrollObj.emailKeyUnique;

        this.$nextTick(async () => {
          await new Promise((resolve) => setTimeout(resolve, 500));

          const el = document.getElementById(scrollObj.emailKeyUnique);

          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });

          scrollObj.emailKeyUnique = '';
          scrollObj.goStatus = 'inactive';
          this.$store.dispatch('setEmailScrollTo', scrollObj);
          setTimeout(() => {
            this.scrollToId = '';
          }, 2000);
        });
      }
    },
    setDivider(selectedEmail) {
      const email = cloneObj(selectedEmail);
      email.multiDaysEmails = [];
      email.duplicatesArr = [];
      email.twoDayDuplicates = [];
      email.divider_email = 1;

      this.optimisticUpdates.dividerChanged = true;
      this.optimisticUpdates.dividerId = selectedEmail.id;

      for (const email of this.emailsAll) {
        email.divider_email = email.id === selectedEmail.id ? 1 : 0;
      }

      store.dispatch('updateEmails', { email }).then((results) => {
        // following line necessary to disable green checkbox
        results.disableCheck = true;

        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response !== 'success') {
            this.$q.notify({
              message: 'Divider move failed',
              color: 'red-14',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
          }
        });
      });
    },
    async setEmails() {
      this.loadingEmails = true;
      // allow loader to show, then move on
      await new Promise((resolve) => setTimeout(resolve, 200));

      this.emailsAll.splice(0);
      this.emailsAllComplete.splice(0);
      this.emailsInbox.splice(0);
      this.emailsInboxComplete.splice(0);
      const emailsMap = new Map();

      const dayAgo = Math.floor(
        +date.subtractFromDate(new Date(), { days: 1 }) / 1000
      );
      const twoMonthsAgo = Math.floor(
        +date.subtractFromDate(new Date(), { months: 2 }) / 1000
      );

      const stateEmails = this.$store.state.emails.filter(
        (x) => x.date > twoMonthsAgo
      );

      const lastUpdateIndex = stateEmails.findIndex(
        (x) => x.email_id === 'last_update'
      );

      const lastUpdate = stateEmails[lastUpdateIndex]?.date ?? null;
      this.lastUpdate = lastUpdate
        ? date.formatDate(new Date(lastUpdate * 1000), 'MMM DD - h:mm A')
        : null;

      if (lastUpdateIndex > -1) {
        stateEmails.splice(lastUpdateIndex, 1);
      }

      for (const email of stateEmails) {
        const emailCopy = cloneObj(email);
        emailCopy.homeUrl = `https://farms.aercon.net/${email.webfolder}/`;
        emailCopy.buoyUrl = `https://farms.aercon.net/${email.webfolder}/BuoyInfo.html`;
        emailCopy.configUrl = `https://farms.aercon.net/${email.webfolder}/Configs.html`;

        emailCopy.formattedDate = date.formatDate(
          new Date(email.date * 1000),
          'ddd MMM DD YYYY - h:mm A'
        );
        emailCopy.msg = JSON.parse(email.message);
        emailCopy.emailBody = emailMsgExtractor(emailCopy.msg);

        const workLogInfo = checkForWorkLog(emailCopy, this.recentLogs);
        emailCopy.hasWorkLog = workLogInfo.numWorkLogs > 0;
        emailCopy.numWorkLogs = workLogInfo.numWorkLogs;
        emailCopy.workLogIds = workLogInfo.workLogIds;

        emailCopy.archived = email.archived === 1;
        emailCopy.checked = false;
        emailCopy.multiDays = false;
        emailCopy.multiDaysEmails = [];

        const emailKey = emailKeyBuilder(emailCopy);
        emailCopy.emailKey = emailKey;
        emailCopy.emailKeyUnique = emailKeyBuilderUnique(emailCopy);

        if (emailsMap.has(emailKey)) {
          emailsMap.get(emailKey).numDuplicates++;
          emailsMap.get(emailKey).duplicatesArr.push(cloneObj(emailCopy));
        } else {
          emailCopy.numDuplicates = 1;
          emailCopy.duplicatesArr = [cloneObj(emailCopy)];
          emailsMap.set(emailKey, emailCopy);
        }
        this.emailsAllComplete.push(emailCopy);
      }

      // create emailsAll array - stacking duplicates and grouping by day
      const emailsAll = [];
      for (const email of emailsMap.values()) {
        if (email.duplicatesArr.length > 1) {
          const days = splitIntoDays(email.duplicatesArr);

          if (days.length > 1) {
            // Mutates days
            getTimeDiff(days);
          }

          emailsAll.push(days[0]);

          const twoDays = getTwoDayDuplicates(days);
        } else {
          emailsAll.push(email);
        }
      }

      const emailsTmp = emailsAll
        .sort((a, b) => b.date - a.date)
        .filter(
          (x) =>
            !x.archived && !this.optimisticUpdates.archivedList.includes(x.id)
        );

      if (this.optimisticUpdates.dividerChanged) {
        for (const email of emailsTmp) {
          email.divider_email =
            email.id === this.optimisticUpdates.dividerId ? 1 : 0;
        }
      }

      this.maxEmailsLen = emailsTmp.length;
      this.emailsAll = emailsTmp.slice(0, this.displayNumEmails);

      this.loadingEmails = false;
    },
    showDuplicates(email) {
      this.twoDayDuplicates = email.twoDayDuplicates;

      this.duplicateEmails.splice(0);

      for (const emailAll of this.emailsAllComplete) {
        if (emailAll.emailKey === email.emailKey) {
          const isInTwoDays = this.twoDayDuplicates.some(
            (x) => x.emailKeyUnique === emailAll.emailKeyUnique
          );

          if (!isInTwoDays) {
            this.duplicateEmails.push(emailAll);
          }
        }
      }

      this.duplicateEmailsType = 'all';
      this.duplicatesDialog = true;
    },
    showMultiDays(email) {
      this.twoDayDuplicates.splice(0);
      this.duplicateEmails = email.multiDaysEmails.slice();

      this.duplicateEmailsType = 'multiDays';
      this.duplicatesDialog = true;
    },
    takeScreenshot() {
      // emulate key press to trigger screenshot
      const event = new KeyboardEvent('keydown', {
        key: '4',
        keyCode: 52,
        ctrlKey: true,
        altKey: false,
        shiftKey: false,
        metaKey: false
      });

      document.dispatchEvent(event);
    },
    workLogAction(email, action) {
      if (email.workLogIds.length === 1) {
        email.workLogId = email.workLogIds[0];
        if (action === 'view') {
          this.goToFarm(email, 'work');
          return;
        }

        this.$store.dispatch('setScreenshotWorkLogId', email.workLogId);
        this.selectedEmail = cloneObj(email);
        this.takeScreenshot();
        return;
      }

      const items = [];
      for (const workLogId of email.workLogIds) {
        const workLog = this.recentLogs.find((x) => x.id === workLogId);
        items.push({
          label: date.formatDate(
            new Date(workLog.date * 1000),
            'ddd, MMM DD - YYYY'
          ),
          value: workLog.id
        });
      }

      this.$q
        .dialog({
          title: 'Select Worklog',
          options: {
            type: 'radio',
            model: email.workLogIds[0],
            items
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-2',
            textColor: 'primary'
          },
          ok: {
            label: 'Select',
            color: 'primary'
          },
          persistent: true
        })
        .onOk((data) => {
          email.workLogId = data;
          if (action === 'view') {
            this.goToFarm(email, 'work');
            return;
          }

          this.$store.dispatch('setScreenshotWorkLogId', email.workLogId);
          this.selectedEmail = cloneObj(email);
          this.takeScreenshot();
        });
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    recentLogs() {
      return this.$store.state.workLogs;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      list.push({
        label: 'Not Assigned',
        value: null
      });
      return list;
    }
  },
  watch: {
    '$store.state.emailWatcher'() {
      this.setEmails();
    },
    '$store.state.workLogs'() {
      this.setEmails();
    },
    '$store.state.screenshot'(newVal, oldVal) {
      if (newVal && this.selectedEmail) {
        this.$store.dispatch('setScreenshotBackToEmail', true);
        this.goToFarm(cloneObj(this.selectedEmail), 'work');
        this.selectedEmail = null;
      }
    }
  }
};
</script>

<style scoped></style>
