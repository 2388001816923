<template>
  <q-card style="width: 420px">
    <div class="row items-center q-ma-md">
      <div class="text-subtitle1">
        {{ FarmName }}
      </div>
      <q-space />
      <q-btn
        unelevated
        icon="clear"
        :color="duplicateEmailsType === 'all' ? 'pink-5' : 'amber-14'"
        text-color="black"
        v-close-popup
      />
    </div>
    <q-separator
      :color="duplicateEmailsType === 'all' ? 'pink-5' : 'amber-14'"
    />

    <div class="scroll" style="max-height: calc(100vh - 200px)">
      <div class="q-pa-md text-subtitle1">
        <div
          v-for="(alert, index) in EmailBody"
          :key="alert.pond + alert.msg + index + 'msg'"
        >
          {{ alert.pond }} -
          {{ alert.msg }}
        </div>
      </div>

      <q-separator
        v-if="
          duplicateEmailsType === 'multiDays' || twoDayDuplicates.length > 0
        "
        :color="duplicateEmailsType === 'all' ? 'pink-5' : 'amber-14'"
      />

      <div v-if="twoDayDuplicates.length > 0">
        <div v-if="duplicateEmailsType === 'all'" class="q-py-sm q-px-md">
          2 Day Duplicates
        </div>
        <div v-if="duplicateEmailsType === 'multiDays'" class="q-py-sm q-px-md">
          Previous 180 days (showing 1 per day)
        </div>
        <q-separator />
      </div>

      <div
        v-for="email in twoDayDuplicates"
        :key="email.email_id + 'dupEmails'"
        class="q-py-xs q-px-md"
      >
        <div>
          {{ email.formattedDate }}
        </div>
      </div>

      <div v-if="duplicateEmailsType === 'all' && duplicateEmails.length > 0">
        <q-separator v-if="duplicateEmailsType === 'all'" color="pink-5" />
        <div class="q-py-sm q-px-md">All Duplicates</div>
        <q-separator />
      </div>

      <div
        v-for="email in duplicateEmails"
        :key="email.email_id + 'dupEmails'"
        class="q-py-xs q-px-md"
      >
        <div>
          {{ email.formattedDate }}
        </div>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'EmailDuplicates',
  props: {
    duplicateEmails: {
      type: Array,
      required: true
    },
    duplicateEmailsType: {
      type: String,
      required: true
    },
    twoDayDuplicates: {
      type: Array,
      required: true
    }
  },
  methods: {},
  computed: {
    FarmName() {
      const farmId = this.Email.farm_id;

      if (farmId === 0) {
        return 'SPAM EMAIL !!!';
      }

      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );

      return farm ? farm.farm_name : '';
    },
    Email() {
      return this.duplicateEmails[0] ?? this.twoDayDuplicates[0];
    },
    EmailBody() {
      return this.Email.emailBody;
    }
  }
};
</script>

<style scoped></style>
